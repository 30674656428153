import React, { useEffect } from 'react';
import { Field, reduxForm, formValueSelector, change, initialize, FormSection } from 'redux-form';
import { Modal, Button } from 'antd';
import { BaseForm } from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FileInput from '../../components/inputs/FileInput';
import styled from 'styled-components';
import { device } from '../../styles/Responsive';
import SelectInput from '../../components/inputs/SelectInput';
import { BillingUsersEnum } from './UtilBillingUsers';
import TextInput from '../../components/inputs/TextInput';
import { getCountryOptions, getCountryTranslation } from '../../infra/services/utils/Countries';
import { isValidPhoneNumber } from 'react-phone-number-input';

export const SectionSubtitle = styled.div`
  text-align: left;
  //padding-top: 15px;
  white-space: pre-line;
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  //margin-top: 40px;

  @media ${device.laptop} {
    font-size: 18px;
    line-height: 26px;
  }

  @media ${device.tablet} {
    font-size: 15px;
    line-height: 24px;
  }
`;

const validations = (values, props) => {
  const { otherUserBillingList, bride, groom } = props?.wedding; // Access extra data
  let error = {};
  error.billing = FormValidator.make({
    user: 'required',
    fullName: 'required',
    identificationNumber: 'required',
    document: 'required'
  })(values?.billing);

  let errorUser = {};
  if ((otherUserBillingList.find(( u ) => u?.fullName?.toLowerCase() === values?.billing?.fullName?.toLowerCase() && values?.billing?.user === 'OTHER') !== undefined) || 
      (bride.fullName.toLowerCase() === values?.billing?.fullName?.toLowerCase() && values?.billing?.user === 'OTHER') || 
      (groom.fullName.toLowerCase() === values?.billing?.fullName?.toLowerCase() && values?.billing?.user === 'OTHER')){
    errorUser.billing = {fullName:'USER_ALREADY_CREATED'};
  }

  const billingErrors = FormValidator.make({
    receipt: "required",
    document: "required",
  })(values);

  error = { ...error, ...billingErrors, ...errorUser };

  return error;
};

let PaymentProofModal = ({
  open,
  edit,
  loading,
  handleSubmit,
  onSubmit,
  closeModal,
  type,
  isProcessClosed,
  translate,
  wedding,
  activeLanguage,
  dispatch,
  change,
  budgetForm,
  onlyView,
  initialValues
}) => {

  const onBillingUserSelect = (billingUser) => {
    const data = { ...budgetForm };
    const selectUser = [];
    selectUser.push(...wedding?.otherUserBillingList);
    selectUser.push(wedding.groom);
    selectUser.push(wedding.bride);
    selectUser.push(wedding?.otherUserBilling);
    data['billing'] = selectUser.find(user => user?.fullName == billingUser);

    dispatch(initialize('add_payment_proof_form', data));
    change('billing', data?.billing);
    change( 'userId', data['billing']?._id);
    change( 'userType', data['billing']?.user);
  }

  const getBillingUsers = () => {
    const billingUsers = [
      { _id: wedding?.groom?.fullName || wedding?.groom?.name, name: wedding?.groom?.fullName || wedding?.groom?.name, user: BillingUsersEnum.GROOM },
      { _id: wedding?.bride?.fullName || wedding?.bride?.name, name: wedding?.bride?.fullName || wedding?.bride?.name, user: BillingUsersEnum.BRIDE },
    ];

    if (wedding?.otherUserBillingList.length > 0){  
      wedding.otherUserBillingList.forEach(element => {
        billingUsers.push({ _id: element.fullName, name: element.fullName, user: BillingUsersEnum.OTHER });
      });
    }
    
    billingUsers.push({ _id: BillingUsersEnum.OTHER, name: 'OTHER' });
    return billingUsers;
  }

  return (
    <Modal
      visible={open}
      title={onlyView ? translate('VIEW_PAYMENT_PROOF') : translate('ADD_PAYMENT_PROOF')}
      maskClosable={false}
      onCancel={closeModal}
      footer={!onlyView ? [
        <Button key="back" onClick={closeModal}>
          {translate('CANCEL')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit(onSubmit)}>
          {translate('SAVE')}
        </Button>
      ] : [<Button key="back" onClick={closeModal}>
        {translate('CANCEL')}
      </Button>]}>
      {open && (
        <BaseForm onSubmit={handleSubmit(onSubmit)}>
          <SectionSubtitle>{translate('PAYMENT_PROOF')}</SectionSubtitle>
          <Field
            disabled={onlyView}
            component={FileInput}
            name={'document'}
            label={`${translate('UPLOAD_PAYMENT_PROOF_FILE')} *`}
            translate={translate}
            filePreview={onlyView ? true : false}
            addFile={onlyView ? false : true}
            accept= {['image/png', 'image/jpeg', 'image/gif', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}
          />

          {onlyView && initialValues?.description.trim() && <Field
            disabled={onlyView}
            component={TextAreaInput}
            name={'description'}
            label={translate('DESCRIPTION')}
            placeholder={translate('DESCRIPTION_PAYMENT_PROOF')}
            inForm={true}
            readOnly={false}
          />}

          {!onlyView && <Field
            disabled={onlyView}
            component={TextAreaInput}
            name={'description'}
            label={translate('DESCRIPTION')}
            placeholder={translate('DESCRIPTION_PAYMENT_PROOF')}
            inForm={true}
            readOnly={false}
          />}

          <SectionSubtitle style={{ marginTop: '20px' }}>{translate('BILLING_DATA')}</SectionSubtitle>
          <FormSection name='billing'>
            <Field
              disabled={onlyView}
              component={SelectInput}
              name={'user'}
              data={getBillingUsers()}
              costumeLabel={(userBilling) => (userBilling.name === 'OTHER') ? translate(userBilling.name) : userBilling.name }
              label={`${translate('BILLING_USER')} *`}
              onChange={(billingUser) => onBillingUserSelect(billingUser)}
            />

            {budgetForm?.billing?.user && <React.Fragment>
              <Field
                disabled={onlyView}
                component={TextInput}
                name={'fullName'}
                type={'text'}
                label={`${translate('FULLNAME_CONTRACT')} *`}
              />
              <Field
                disabled={onlyView}
                component={SelectInput}
                name={'nationality'}
                data={getCountryOptions()}
                dataKey={'value'}
                costumeLabel={(country) => getCountryTranslation(activeLanguage, country)}
                label={`${translate('NATIONALITY_CONTRACT')}`}
              />

              <Field
                disabled={onlyView}
                component={TextInput}
                name={'identificationNumber'}
                type={'text'}
                label={`${translate('IDENTIFICATION_NUMBER_CONTRACT')} *`}
              />

              <Field
                disabled={onlyView}
                component={TextInput}
                name={'streetDoorNumber'}
                type={'text'}
                label={`${translate('STREET_DOOR_CONTRACT')}`}
              />

              <Field
                disabled={onlyView}
                component={TextInput}
                name={'zipCode'}
                type={'text'}
                label={`${translate('ZIP_CODE_CONTRACT')}`}
              />

              <Field
                disabled={onlyView}
                component={TextInput}
                name={'street'}
                type={'text'}
                label={`${translate('STREET_CONTRACT')}`}
              />

              <Field
                disabled={onlyView}
                component={SelectInput}
                name={'country'}
                data={getCountryOptions()}
                dataKey={'value'}
                costumeLabel={(country) => getCountryTranslation(activeLanguage, country)}
                label={`${translate('COUNTRY_CONTRACT')}`}
              />

            </React.Fragment>}
          </FormSection>
        </BaseForm>
      )}
    </Modal>
  );
};


PaymentProofModal = reduxForm({
  form: 'add_payment_proof_form',
  validate: validations
})(PaymentProofModal);

const selector = formValueSelector('add_payment_proof_form');

const mapStateToProps = state => ({
  type: selector(state, 'type'),
  budgetForm: {
    document: selector(state, 'document'),
    description: selector(state, 'description'),
    billing: {
      user: selector(state, 'billing.user')
    }
  }
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ change }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentProofModal);
